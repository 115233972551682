@media print {
    html, body {
        height: initial !important;
        /*margin: 0 !important;*/
        /*padding: 0 !important;*/
        overflow: visible !important;
        -webkit-print-color-adjust: exact;
        background-color: #FFF;
        color: #1A2027;
        font: 12pt Georgia, "Times New Roman", Times, serif;
        line-height: 1.3;
    }
    /*.MuiBox-root {*/
    /*    background-color: #FFF;*/
    /*    color: #1A2027*/
    /*}*/
    /*.MuiGrid-root, MuiGrid-item {*/
    /*    background-color: #FFF;*/
    /*    color: #1A2027*/
    /*}*/
    /*.MuiPaper-root {*/
    /*    background-color: #FFF;*/
    /*    color: #1A2027*/
    /*}*/
    /*.MuiAccordion-root {*/
    /*    background-color: #FFF;*/
    /*    color: #1A2027*/
    /*}*/
    /*MuiTypography-root {*/
    /*    background-color: #FFF;*/
    /*    color: #1A2027*/
    /*}*/
    .print-container > * {
        background-color: #FFF;
        color: #1A2027
    }
    /*div {*/
    /*    background-color: #FFF;*/
    /*    color: #1A2027*/
    /*}*/
    .print-hide {
        display: none;
    }
}

@media print {
    .page-break {
        margin-top: 1rem;
        display: none;
        break-before: auto;
    }
}

@page {
    /*size: legal landscape;*/
    margin: .2cm;
}
